<template>
  <div>
    <!-- Desktop View -->
    <div class="hidden md:flex justify-around items-center px-6 w-full">
      <NuxtLink to="/" class="flex mt-5">
        <logo-color />
      </NuxtLink>
      <div class=" text-custom-black-1 text-20 pr-10 flex flex-wrap justify-end mt-6 mb-2">
        <span class="mr-4 font-bold">Call Us: <a :href="`tel:${phoneNumber}`" class="font-normal">{{ phoneNumber }}</a></span>
        <span class="font-bold">Email Us: <a href="mailto:info@gocanopyroofing.com" class="font-normal">info@gocanopyroofing.com</a></span>
      </div>
    </div>

    <!-- Mobile View -->
    <div class="md:hidden flex items-center px-6 justify-between text-custom-black-1 h-14 bg-gray-100">
      <span class="text-[#151313] text-base font-bold">Call Us: <a :href="`tel:${phoneNumber}`" class="font-normal">{{ phoneNumber }}</a></span>
      <CommonButtonsEstimate text="Free Estimate" />
    </div>
  </div>
</template>

<script setup lang="ts">
import { usePhoneNumber } from '@/composables/usePhoneNumber'

const phoneNumber = usePhoneNumber()
</script>
